import React from "react";

import clsx from "clsx";

import styles from "./MenuButton.module.css";

type Props = {
  isMenuOpen: boolean;
  onMouseEnter?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onClick?: () => void;
};

const MenuButton = (props: Props) => {
  return (
    <div
      className={styles.menuButtonWrapper}
      onMouseEnter={(event) => {
        props.onMouseEnter && props.onMouseEnter(event);
      }}
      onClick={() => {
        props.onClick && props.onClick();
      }}
    >
      <div
        className={clsx(styles.menuButton, {
          [styles.crossAnimation]: props.isMenuOpen === true,
        })}
      >
        <div className={styles.middleBar} />
      </div>
    </div>
  );
};

export default MenuButton;
