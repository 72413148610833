import clsx from "clsx";
import Image from "next/image";
import Link from "next/link";

import styles from "./YoyoLogo.module.scss";

type Props = {
  size?: "sm" | "md" | "bg";
  hideTextInSmallScreen?: boolean;
  forceHideText?: boolean;
};

const YoyoLogo = (props: Props) => {
  const getSize = () => {
    return props.size ?? "md";
  };

  return (
    <Link
      href="/"
      className={clsx(styles.logo, {
        [styles.small]: getSize() === "sm",
        [styles.big]: getSize() === "bg",
        [styles["hide-text"]]: props.hideTextInSmallScreen,
      })}
    >
      <Image
        priority
        alt="Yoyo Shortcut Logo"
        src="/logo.svg"
        width={getSize() === "sm" ? 30 : getSize() === "md" ? 40 : 50}
        height={getSize() === "sm" ? 30 : getSize() === "md" ? 40 : 50}
      />

      <span
        style={{
          display: props.forceHideText ? "none" : undefined,
        }}
      >
        Yoyo Shortcut
      </span>
    </Link>
  );
};

export default YoyoLogo;
