import { useEffect, useState } from "react";
import React from "react";

import { CircularProgress, Tooltip } from "@mui/material";
import { useRouter } from "next/router";

import ArrowRightIcon from "@/public/arrow-right.svg";

import {
  EarlyAccessStatus,
  TRIAL_DURATION_IN_SECONDS,
} from "../../../cross-platform-resources/back-end/EarlyAccess";
import { sentryReport } from "../../../utils/Error";
import useAuth from "../../../utils/Hooks/useAuth";
import GetSubscription from "../../../utils/Queries/pricing/get-subscription";
import CustomPopper from "../../CustomPopper";
import RedeemOfferModal from "../../PromotionModal/RedeemOfferModal";

import styles from "./TrialTimerChip.module.scss";

type Props = {
  isPopperMenuOpen: boolean;
};

const TrialTimerChip = (props: Props) => {
  const router = useRouter();
  const { authStatus } = useAuth({});

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [earlyAccessProgress, setEarlyAccessProgress] = useState(100);

  const [redeemOfferModal, setRedeemOfferModal] = useState({
    open: false,
  });

  const {
    isLoading,
    isRefetching,
    data: subscription_data,
    error: subscription_error,
  } = GetSubscription.useQueryWrapper({
    enabled: authStatus === "authenticated",
  });

  // Check error of query
  useEffect(() => {
    if (isLoading === false && isRefetching === false && subscription_error) {
      sentryReport(subscription_error);
    }
  }, [isLoading, isRefetching, subscription_error]);

  // Effect for setting progress bar
  useEffect(() => {
    let intervalUpdateProgress = -1;

    if (
      subscription_data?.earlyAccessInfo &&
      subscription_data?.earlyAccessInfo.status === EarlyAccessStatus.ACTIVE
    ) {
      // Set progress bar
      setEarlyAccessProgress(
        (remainingTimeInSeconds() / TRIAL_DURATION_IN_SECONDS) * 100
      );
      intervalUpdateProgress = window.setInterval(() => {
        setEarlyAccessProgress(
          (remainingTimeInSeconds() / TRIAL_DURATION_IN_SECONDS) * 100
        );
      }, 5 * 1000);
    }

    return () => {
      window.clearInterval(intervalUpdateProgress);
    };
  }, [JSON.stringify(subscription_data)]);

  // Auto close redeem offer modal if user redeems offer
  // redeemOfferModal is not auto closed if user already has a payment method
  useEffect(() => {
    if (subscription_data?.promotion.earlyAccessPromotion) {
      setRedeemOfferModal({ open: false });
    }
  }, [subscription_data?.promotion.earlyAccessPromotion]);

  const remainingTimeInSeconds = () => {
    if (earlyAccessInfo?.activeAt) {
      const remainSeconds =
        TRIAL_DURATION_IN_SECONDS -
        (new Date().getTime() / 1000 -
          new Date(earlyAccessInfo.activeAt).getTime() / 1000);

      if (remainSeconds < 0) {
        return 0;
      }

      return remainSeconds;
    }

    return 0;
  };

  const showRemainingTime = () => {
    // 1 day = 24 * 60 * 60
    const remainDays = Math.floor(remainingTimeInSeconds() / (24 * 60 * 60));
    if (remainDays < 1) {
      return "< 1";
    }
    return remainDays;
  };

  const earlyAccessInfo = subscription_data?.earlyAccessInfo;

  if (
    !earlyAccessInfo ||
    earlyAccessInfo.status === EarlyAccessStatus.OUTDATED
  ) {
    return null;
  }

  return (
    <CustomPopper
      placement="bottom-end"
      open={props.isPopperMenuOpen ? false : undefined}
      classes={{
        popperWrapper: styles.popperWrapper,
      }}
      icon={({ open: popperOpen }) => (
        <Tooltip
          open={popperOpen ? false : tooltipOpen}
          title="Click to learn more"
          classes={{ tooltip: styles.tooltip }}
          placement="bottom-end"
        >
          <div
            className={styles.root}
            onMouseEnter={() => {
              setTooltipOpen(true);
            }}
            onMouseLeave={() => {
              setTooltipOpen(false);
            }}
          >
            <div className={styles.progressContainer}>
              <CircularProgress
                variant="determinate"
                value={earlyAccessProgress}
                thickness={8}
                className={styles.progress}
              />
              <div className={styles.progressCenter}>{showRemainingTime()}</div>
            </div>
            <div className={styles.chip}>days left</div>
          </div>
        </Tooltip>
      )}
      popperContent={() => (
        <div className={styles.popperContent}>
          {!subscription_data.promotion.earlyAccessPromotion && (
            <React.Fragment>
              <p>
                ⏳ {showRemainingTime()} days left until your trial of Premium
                plan expires. In case you’re in love with Premium access, we
                have an offer for you:
              </p>
              <button
                onClick={() => {
                  setRedeemOfferModal({ open: true });
                }}
              >
                🎉 25% off for next 6 months <ArrowRightIcon />
              </button>

              <RedeemOfferModal
                open={redeemOfferModal.open}
                onClose={() => {
                  setRedeemOfferModal({ open: false });
                }}
              />
            </React.Fragment>
          )}

          {subscription_data.promotion.earlyAccessPromotion && (
            <React.Fragment>
              <p>
                ⏳ {showRemainingTime()} days left until your trial of Premium
                plan expires. After that, your subscription of Premium plan will
                start with 25% off for the first 6-month period. For more
                information, go to your Account page.
              </p>
              <button
                className={styles.purpleButton}
                onClick={() => {
                  router.push("/account");
                }}
              >
                Go to Account page <ArrowRightIcon />
              </button>
            </React.Fragment>
          )}
        </div>
      )}
    />
  );
};

export default TrialTimerChip;
