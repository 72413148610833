import React from "react";

import axios from "axios";
import clsx from "clsx";

import { sentryReport } from "@/utils/Error";

import styles from "./TryForFreeButton.module.css";

type Props = {
  size: "bg" | "sm";
  buttonLocation: string;
  text?: string;
  className?: string;
};

const TryForFreeButton = (props: Props) => {
  return (
    <div
      className={clsx(props.className, styles["try-for-free"], {
        [styles["try-for-free-bg"]]: props.size === "bg",
        [styles["try-for-free-sm"]]: props.size === "sm",
      })}
      onClick={() => {
        window.open(
          `https://chromewebstore.google.com/detail/yoyo-shortcut/${process.env.NEXT_PUBLIC_CHROME_EXTENSION_ID}`,
          "_blank"
        );

        // Logging should not intervene or block other code
        axios({
          method: "post",
          url: `${process.env.NEXT_PUBLIC_BACKEND_HOST}/log/try-free-clicks`,
          data: {
            buttonName: props.buttonLocation,
          },
        }).catch((err) => {
          sentryReport(err);
        });
      }}
    >
      {props.text ?? "Try for free"}
    </div>
  );
};

export default TryForFreeButton;
