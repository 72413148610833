import React, { useState, useEffect } from "react";

import { CircularProgress } from "@mui/material";
import clsx from "clsx";
import Link from "next/link";
import { useRouter } from "next/router";
import { LuLayoutDashboard } from "react-icons/lu";
import { MdExitToApp } from "react-icons/md";
import { RiAccountCircleLine } from "react-icons/ri";

import ArrowRightIcon from "@/public/arrow-right.svg";

import {
  EarlyAccessStatus,
  SCHEDULE_FORCE_CANCEL_MARGIN_IN_SECONDS,
  TRIAL_DURATION_IN_SECONDS,
} from "../../cross-platform-resources/back-end/EarlyAccess";
import { sentryReport } from "../../utils/Error";
import useAuth, { signOutWrapper } from "../../utils/Hooks/useAuth";
import GetSubscription from "../../utils/Queries/pricing/get-subscription";
import CustomPopper from "../CustomPopper";
import HeadBanners from "../HeadBanners";
import MenuButton from "../MenuButton";
import AfterAddPaymentModal from "../PromotionModal/AfterAddPaymentModal";
import RedeemOfferModal from "../PromotionModal/RedeemOfferModal";
import TryForFreeButton from "../TryForFreeButton";
import YoyoLogo from "../YoyoLogo";
import NavbarSmall from "./NavbarSmall";
import TrialTimerChip from "./TrialTimerChip";

import styles from "./Navbar.module.scss";

type Props = {
  isFloat?: boolean; // Position of nav wrapper is "fixed", not "sticky"
  isBlendIn?: boolean; // Background transparent

  hidden?: boolean; // If used, Navbar will have opacity transition during hidden or shown
  hiddenClass?: string; // adding className for hidden

  hideTryForFree?: boolean;
};

const Navbar = (props: Props) => {
  const router = useRouter();
  const { authStatus, isAuthenticated } = useAuth({});

  const [isSmallScreen, setIsSmallScreen] = useState(false);

  const [isSmallScreenMenuOpen, setSmallScreenMenuOpen] = useState(false);
  const [isPopperMenuOpen, setPopperMenuOpen] = useState(false);

  const [isEarlyAccessPopperOpen, setIsEarlyAccessPopperOpen] = useState(false);
  const [earlyAccessProgress, setEarlyAccessProgress] = useState(100);

  const [redeemOfferModal, setRedeemOfferModal] = useState({
    open: false,
  });

  const {
    isLoading,
    isRefetching,
    data: subscription_data,
    error: subscription_error,
    refetch,
  } = GetSubscription.useQueryWrapper({
    enabled: authStatus === "authenticated",
  });

  // Check error of query
  useEffect(() => {
    if (isLoading === false && isRefetching === false && subscription_error) {
      sentryReport(subscription_error);
    }
  }, [isLoading, isRefetching, subscription_error]);

  // Effect for:
  // - Refetch data for early access when expire
  // - Set progress bar
  useEffect(() => {
    let timeoutRefetch = -1;
    let intervalRefetch = -1;
    let intervalUpdateProgress = -1;

    if (
      subscription_data?.earlyAccessInfo &&
      subscription_data?.earlyAccessInfo.status === EarlyAccessStatus.ACTIVE
    ) {
      let timeoutDelayInSeconds = remainingTimeInSeconds();

      // If no set up promotion, delay extra 5 minutes (trial cancel at margin)
      if (!subscription_data.promotion.earlyAccessPromotion) {
        timeoutDelayInSeconds += SCHEDULE_FORCE_CANCEL_MARGIN_IN_SECONDS;
      }

      // Refetch early access when expire
      timeoutRefetch = window.setTimeout(() => {
        refetch();
        intervalRefetch = window.setInterval(() => {
          refetch();
        }, 5 * 1000);
      }, timeoutDelayInSeconds * 1000);

      // Set progress bar
      setEarlyAccessProgress(
        (remainingTimeInSeconds() / TRIAL_DURATION_IN_SECONDS) * 100
      );
      intervalUpdateProgress = window.setInterval(() => {
        setEarlyAccessProgress(
          (remainingTimeInSeconds() / TRIAL_DURATION_IN_SECONDS) * 100
        );
      }, 5 * 1000);
    }

    return () => {
      window.clearTimeout(timeoutRefetch);
      window.clearInterval(intervalRefetch);
      window.clearInterval(intervalUpdateProgress);
    };
  }, [JSON.stringify(subscription_data)]);

  // Auto close redeem offer modal if user redeems offer
  // redeemOfferModal is not auto closed if user already has a payment method
  useEffect(() => {
    if (subscription_data?.promotion.earlyAccessPromotion) {
      setRedeemOfferModal({ open: false });
    }
  }, [subscription_data?.promotion.earlyAccessPromotion]);

  // Effect for hidden navbar
  useEffect(() => {
    if (props.hidden) {
      setSmallScreenMenuOpen(false);
      setPopperMenuOpen(false);
    }
  }, [props.hidden]);

  // Effect for small screen navbar
  useEffect(() => {
    if (isSmallScreen) {
      setPopperMenuOpen(false);
    } else {
      setSmallScreenMenuOpen(false);
    }
  }, [isSmallScreen]);

  // Effect for setting small screen
  useEffect(() => {
    setIsSmallScreen(window.matchMedia("(max-width: 767px)").matches);
    window.addEventListener("resize", handleResize);

    // Unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleResize = () => {
    setIsSmallScreen(window.matchMedia("(max-width: 767px)").matches);
  };

  const logout = () => {
    signOutWrapper();
  };

  const remainingTimeInSeconds = () => {
    if (earlyAccessInfo?.activeAt) {
      const remainSeconds =
        TRIAL_DURATION_IN_SECONDS -
        (new Date().getTime() / 1000 -
          new Date(earlyAccessInfo.activeAt).getTime() / 1000);

      if (remainSeconds < 0) {
        return 0;
      }

      return remainSeconds;
    }

    return 0;
  };

  const showRemainingTime = () => {
    // 1 day = 24 * 60 * 60
    const remainDays = Math.floor(remainingTimeInSeconds() / (24 * 60 * 60));
    if (remainDays < 1) {
      return "< 1";
    }
    return remainDays;
  };

  const earlyAccessInfo = subscription_data?.earlyAccessInfo;
  let timeoutCloseEarlyAccessPopper = -1;

  return (
    <div
      className={clsx(styles["nav-wrapper"], {
        [styles.float]: props.isFloat,
        [styles.blend]: props.isBlendIn,
        [clsx(styles.hidden, props.hiddenClass)]: props.hidden,
      })}
    >
      <AfterAddPaymentModal />

      <HeadBanners />

      <nav className={styles.nav}>
        {isSmallScreen === true && (
          <NavbarSmall
            isMenuOpen={isSmallScreenMenuOpen}
            setIsMenuOpen={setSmallScreenMenuOpen}
            isPopperMenuOpen={isPopperMenuOpen}
            {...props}
          />
        )}

        {isSmallScreen === false && (
          <React.Fragment>
            <div className={styles["nav-left"]}>
              <YoyoLogo />
            </div>

            {!isAuthenticated && (
              <div className={clsx(styles["nav-right"], "gap-2")}>
                <div className={styles.links}>
                  <Link href="/about" className={styles.link}>
                    About Us
                  </Link>
                  <Link href="/pricing" className={styles.link}>
                    Pricing
                  </Link>
                  <Link
                    href="https://guide.yoyoshortcut.com/"
                    target="_blank"
                    className={styles.link}
                  >
                    Guide
                  </Link>
                </div>

                <div className="h-[32px] bg-[--color-divider-light] w-[1px]" />

                <div className="flex">
                  <Link href="/signin" className={clsx(styles.link)}>
                    Sign in
                  </Link>

                  {!props.hideTryForFree && (
                    <TryForFreeButton
                      size="sm"
                      buttonLocation="Navbar - Try For Free Button"
                    />
                  )}
                </div>
              </div>
            )}

            {isAuthenticated && (
              <div className={clsx(styles["nav-right"], "gap-2")}>
                <div className={styles.links}>
                  <Link href="/about" className={styles.link}>
                    About Us
                  </Link>
                  <Link href="/pricing" className={styles.link}>
                    Pricing
                  </Link>
                  <Link
                    href="https://guide.yoyoshortcut.com/"
                    target="_blank"
                    className={styles.link}
                  >
                    Guide
                  </Link>
                </div>

                <div className="h-[32px] bg-[--color-divider-light] w-[1px]" />

                <div className="flex items-center">
                  <TrialTimerChip isPopperMenuOpen={isPopperMenuOpen} />

                  <CustomPopper
                    open={isPopperMenuOpen}
                    setOpen={setPopperMenuOpen}
                    classes={{
                      popperWrapper: styles["popper-wrapper"],
                      popper: styles.popper,
                    }}
                    placement="bottom-end"
                    icon={({ setAnchorEl }) => (
                      <div className="ml-6">
                        <MenuButton
                          isMenuOpen={isPopperMenuOpen}
                          onMouseEnter={(event) => {
                            setAnchorEl(event.currentTarget);
                            setPopperMenuOpen(true);
                          }}
                        />
                      </div>
                    )}
                    popperContent={({ popperStyles }) => (
                      <React.Fragment>
                        <Link
                          href="/board"
                          className={clsx(
                            popperStyles["popper-item"],
                            styles["popper-item-extend"]
                          )}
                        >
                          <div>
                            <LuLayoutDashboard />
                            Home Board
                          </div>
                        </Link>

                        <Link
                          href="/account"
                          className={clsx(
                            popperStyles["popper-item"],
                            styles["popper-item-extend"]
                          )}
                        >
                          <div>
                            <RiAccountCircleLine />
                            Account
                          </div>
                        </Link>

                        {earlyAccessInfo?.status ===
                          EarlyAccessStatus.ACTIVE && (
                          <React.Fragment>
                            <div className={styles.divider} />

                            <CustomPopper
                              arrow
                              placement="left"
                              classes={{
                                popperWrapper: styles.earlyAccessPopperWrapper,
                              }}
                              open={isEarlyAccessPopperOpen}
                              icon={({ popperStyles, setAnchorEl }) => (
                                <div
                                  className={clsx(
                                    popperStyles["popper-item"],
                                    styles["popper-item-extend"]
                                  )}
                                  onMouseEnter={(event) => {
                                    window.clearTimeout(
                                      timeoutCloseEarlyAccessPopper
                                    );
                                    setIsEarlyAccessPopperOpen(true);
                                    setAnchorEl(event.currentTarget);
                                  }}
                                  onMouseLeave={() => {
                                    timeoutCloseEarlyAccessPopper =
                                      window.setTimeout(() => {
                                        setIsEarlyAccessPopperOpen(false);
                                      }, 200);
                                  }}
                                >
                                  <div className={styles.progressContainer}>
                                    <CircularProgress
                                      variant="determinate"
                                      value={earlyAccessProgress}
                                      thickness={8}
                                      className={styles.progress}
                                    />
                                    <div className={styles.progressCenter}>
                                      {showRemainingTime()}
                                    </div>
                                  </div>

                                  <div>
                                    days left until Premium trial expires
                                  </div>
                                </div>
                              )}
                              popperContent={() => (
                                <div
                                  className={styles.earlyAccessPopperContent}
                                  onMouseEnter={() => {
                                    window.clearTimeout(
                                      timeoutCloseEarlyAccessPopper
                                    );
                                    setIsEarlyAccessPopperOpen(true);
                                  }}
                                  onMouseLeave={() => {
                                    timeoutCloseEarlyAccessPopper =
                                      window.setTimeout(() => {
                                        setIsEarlyAccessPopperOpen(false);
                                      }, 200);
                                  }}
                                >
                                  {!subscription_data?.promotion
                                    .earlyAccessPromotion && (
                                    <React.Fragment>
                                      <p>
                                        ⏳ {showRemainingTime()} days left until
                                        your trial of Premium plan expires. In
                                        case you’re in love with Premium access,
                                        we have an offer for you:
                                      </p>

                                      <button
                                        onClick={() => {
                                          setIsEarlyAccessPopperOpen(false);
                                          setRedeemOfferModal({
                                            open: true,
                                          });
                                        }}
                                      >
                                        🎉 25% off for next 6 months{" "}
                                        <ArrowRightIcon />
                                      </button>
                                    </React.Fragment>
                                  )}

                                  {subscription_data?.promotion
                                    .earlyAccessPromotion && (
                                    <React.Fragment>
                                      <p>
                                        ⏳ {showRemainingTime()} days left until
                                        your trial of Premium plan expires.
                                        After that, your subscription of Premium
                                        plan will start with 25% off for the
                                        first 6-month period. For more
                                        information, go to your Account page.
                                      </p>
                                      <button
                                        className={styles.purpleButton}
                                        onClick={() => {
                                          router.push("/account");
                                        }}
                                      >
                                        Go to Account page <ArrowRightIcon />
                                      </button>
                                    </React.Fragment>
                                  )}
                                </div>
                              )}
                            />

                            <RedeemOfferModal
                              open={redeemOfferModal.open}
                              onClose={() => {
                                setRedeemOfferModal({
                                  open: false,
                                });
                              }}
                            />
                          </React.Fragment>
                        )}

                        <div className={styles.divider} />

                        <a
                          className={clsx(
                            popperStyles["popper-item"],
                            styles["popper-item-extend"]
                          )}
                          onClick={logout}
                        >
                          <div>
                            <MdExitToApp />
                            Sign out
                          </div>
                        </a>
                      </React.Fragment>
                    )}
                  />
                </div>
              </div>
            )}
          </React.Fragment>
        )}
      </nav>
    </div>
  );
};

export default Navbar;
